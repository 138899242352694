<template>
	<w-layout fill-height row>
		<w-flex offset-xs1 xs4 mr-2>
			<w-layout column fill-height justify-center>
				<w-flex v-t="'videoconference.title'" display-1 mb-4 shrink />
				<w-flex mb-2 shrink subheading>{{ description }}</w-flex>
				<w-flex v-t="'videoconference.text.all'" mb-2 shrink subheading />
			</w-layout>
		</w-flex>
		<w-flex xs6 ml-2>
			<w-layout align-center fill-height>
				<VideoConferenceHomeImage height="80%" />
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import VideoConferenceModuleGuard from '@/mixins/ModulesGuards/VideoConference/VideoConferenceModuleGuard'

export default {
	name: 'VideoConferenceHome',
	components: {
		VideoConferenceHomeImage: () => ({
			component: import('@/components/VideoConference/VideoConferenceHomeImage')
		})
	},
	mixins: [VideoConferenceModuleGuard],
	computed: {
		...mapState({
			isAccountant: state => state.accountingFirm.selected.isAccountant
		}),
		description: function () {
			let result
			if (this.isAccountant) {
				result = this.$t('videoconference.text.accountant')
			} else {
				result = this.$t('videoconference.text.not_accountant')
			}
			return result
		}
	}
}
</script>
